/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    p: "p",
    code: "code",
    pre: "pre"
  }, props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h3, {
      children: "Transitions"
    }), "\n", _jsxs(_components.p, {
      children: ["If you'd like to customize your values for transitions, use the ", _jsx(_components.code, {
        children: "theme.transitions"
      }), " section of your theme."]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-diffjs",
        children: "  // theme.js\n\n  export const theme = {\n    transitions: {\n      // ...\n+     light: 'color 300ms ease-in-out'\n    },\n  }\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["If you don't want to customize it, a set of ", _jsx(_components.code, {
        children: "transitions"
      }), " is already present in default theme. Values are the same as the values accepted in ", _jsx(_components.code, {
        children: "transitionProperty"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = props.components || ({});
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
