/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import CustomizeTransitions from '../../../partials/customizing/transitions.mdx';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    code: "code",
    h2: "h2",
    pre: "pre",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "transition",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#transition",
    "aria-label": "transition permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Transition"), "\n", React.createElement(_components.p, null, "Utilities for controlling transitions."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "React props"), React.createElement(_components.th, null, "CSS Properties"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "transition={value}")), React.createElement(_components.td, null, React.createElement(_components.code, null, "transition: {value};"))))), "\n", React.createElement(_components.h2, {
    id: "usage",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#usage",
    "aria-label": "usage permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Usage"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "transition={value}"), " to specify transitions."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=amber"
  }, "<>\n  <template preview>\n    <x.div textAlign=\"center\">\n      <x.button\n        fontWeight=\"medium\"\n        transition\n        transitionDuration={500}\n        color=\"white\"\n        bg={{ _: 'amber-600', hover: 'orange-600' }}\n        py={3}\n        px={6}\n        borderRadius=\"md\"\n        border={0}\n      >\n        Hover me\n      </x.button>\n    </x.div>\n  </template>\n  <x.button\n    transition\n    transitionDuration={500}\n    color=\"white\"\n    bg={{ _: 'amber-600', hover: 'orange-600' }}\n    py={3}\n    px={6}\n    borderRadius=\"md\"\n  >\n    Hover me\n  </x.button>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "prefers-reduced-motion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#prefers-reduced-motion",
    "aria-label": "prefers reduced motion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Prefers-reduced-motion"), "\n", React.createElement(_components.p, null, "You can conditionally apply animations and transitions using the ", React.createElement(_components.code, null, "motionSafe"), " and ", React.createElement(_components.code, null, "motionReduce"), " states:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<x.button\n  color={{ hover: 'red' }}\n  transition={{ _: true, motionReduce: 'none' }}\n>\n  Hover me\n</x.button>\n")), "\n", React.createElement(_components.h2, {
    id: "responsive",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#responsive",
    "aria-label": "responsive permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Responsive"), "\n", React.createElement(_components.p, null, "To control the transitions on an element at a specific breakpoint, use responsive object notation. For example, adding the property ", React.createElement(_components.code, null, "transition={{ md: \"all\" }}"), " to an element would apply the ", React.createElement(_components.code, null, "transition=\"all\""), " utility at medium screen sizes and above."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<x.div transition={{ md: 'all' }} />\n")), "\n", React.createElement(_components.p, null, "For more information about xstyled's responsive design features, check out ", React.createElement(_components.a, {
    href: "/docs/responsive-design/"
  }, "Responsive Design"), " documentation."), "\n", React.createElement(_components.h2, {
    id: "customizing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#customizing",
    "aria-label": "customizing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Customizing"), "\n", "\n", React.createElement(CustomizeTransitions), "\n", React.createElement(_components.h2, {
    id: "styled-bindings",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#styled-bindings",
    "aria-label": "styled bindings permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Styled bindings"), "\n", React.createElement(_components.h3, {
    id: "automatic",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#automatic",
    "aria-label": "automatic permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Automatic"), "\n", React.createElement(_components.p, null, "Using xstyled's ", React.createElement(_components.code, null, "styled"), ", all transitions defined are automatically bound to ", React.createElement(_components.code, null, "transition"), " attribute:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import styled from '@xstyled/...'\n\nconst Button = styled.button`\n  transition: default;\n`\n")), "\n", React.createElement(_components.p, null, "To learn more about styled syntax, read ", React.createElement(_components.a, {
    href: "/docs/magic-styled-components/"
  }, "styled syntax documentation"), "."), "\n", React.createElement(_components.h3, {
    id: "manual",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#manual",
    "aria-label": "manual permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Manual"), "\n", React.createElement(_components.p, null, "It is possible to manually bind a transition using ", React.createElement(_components.code, null, "th.transition"), " utility:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import styled from '...'\nimport { th } from '@xstyled/...'\n\nconst Button = styled.button`\n  transition: ${th.transition('default')};\n`\n")), "\n", React.createElement(_components.h2, {
    id: "hooks",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hooks",
    "aria-label": "hooks permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hooks"), "\n", React.createElement(_components.p, null, "Get a transition in any component using ", React.createElement(_components.code, null, "useTransition"), " hook:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { useTransition } from '@xstyled/...'\n\nfunction Button() {\n  const transition = useTransition('default')\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
